/**
 * Classe responsável por buscar os certificados do usuário.
 *
 * @type {Certificate}
 */

class Certificate {

    /**
     * Método responsável por buscar o certificado no assinador(ruby).
     *
     * @param {string} cpf
     * @return {undefined}
     */
    static async getCertificatesByCpf(cpf) {
        try {
            const headers = new Headers();
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');

            const result = await fetch(`/digitalsignaturegetcertificates/getandpersist/cpf/${cpf}`, {
                method: 'GET',
                headers,
                credentials: 'same-origin'
            });

            if (result.status >= 400)
                throw new Error();

            return await result.json();
        } catch (e) {
            throw e;
        }
    }

}
