class Utils {

    static PROCESSANDO_ASSINATURA = 0;
    static ERRO_AO_ASSINAR = 1;
    static SITUACAO_PENDENTE = 2;
    static FALHA_AO_ASSINAR = 3;
    static SITUACAO_ASSINADO = 4;
    static FALHA_OBTER_ARQUIVO_ASSINADO = 5;
    static FALHA_SALVAR_ARQUIVO_ASSINADO = 6;
    static PROCESSO_FINALIZADO = 7;

    static _optionsSituacaoAssinatura = {
        [Utils.PROCESSANDO_ASSINATURA]: 'Processando',
        [Utils.ERRO_AO_ASSINAR]: 'Com erro ao assinar',
        [Utils.SITUACAO_PENDENTE]: 'Pendente de assinatura',
        [Utils.FALHA_AO_ASSINAR]: 'Falha ao assinar',
        [Utils.SITUACAO_ASSINADO]: 'Assinado',
        [Utils.FALHA_OBTER_ARQUIVO_ASSINADO]: 'Falha ao obter o arquivo assinado',
        [Utils.FALHA_SALVAR_ARQUIVO_ASSINADO]: 'Falha ao salvar o arquivo assinado',
        [Utils.PROCESSO_FINALIZADO]: 'Processo finalizado'
    };

    /**
     * Retorna o objeto com as opções de situação de assinatura.
     *
     * @return {object}
     */
    static getSituacaoAssinatura() {
        return this._optionsSituacaoAssinatura;
    }

    /**
     * Retorna a string correspondente ao ID da situação de assinatura.
     *
     * @param {number} key
     * @return {string}
     */
    static getSituacaoAssinaturaStr(key) {
        return this._optionsSituacaoAssinatura[key];
    }

    /**
     * Retorna o ID correspondente à string da situação de assinatura.
     *
     * @param {string} value
     * @return {number|null}
     */
    static getSituacaoAssinaturaId(value) {
        const key = Object.keys(this._optionsSituacaoAssinatura).find(
            k => this._optionsSituacaoAssinatura[k] === value
        );
        return key ? parseInt(key, 10) : null;
    }

    /**
     * Método responsável por formatar a mensagem com os dados do certificado.
     *
     * @param dataValidSignature
     * @param dataStatusSignature
     */
    static factoryMessageCertificates(dataValidSignature = null, dataStatusSignature = null) {

        if ((dataStatusSignature.status_id === Utils.SITUACAO_ASSINADO || dataStatusSignature.status_id === Utils.PROCESSO_FINALIZADO) && dataStatusSignature) {
            return 'Assinado com sucesso!';
        } else {
            return "Solicitação de assinatura realizada.";
        }
        
    }


    /**
     * Método responsável por formatar a mensagem de erro.
     *
     * @param errors
     */
    static factoryMessageErrorsSign(resultSign = null) {
        let message = "Houve um problema na assinatura/verificação\n\n";

        return message;

        if (resultSign.resultSign.validTSA === false)
            message += "- Houve um erro ao validar o servidor de carimbo de tempo: \n\n";

        if (resultSign.resultSign.validlCR === false)
            message += "- Houve um erro ao validar a LCR: \n\n";

        if (resultSign.resultSign.errorsCertificate.length) {
            message += "\nErros certificado digital: \n\n";

            message += resultSign.resultSign.errorsCertificate.join('\n');
        }

        message += "\n\nTente novamente mais tarde. O registro permanecerá pendende para assinatura.";

        return message;
    }

    /**
     * Método responsável por abrir um modal com o conteúdo informado no parâmetro data.
     *
     * @param data
     * @param title
     */
    static openBlockUI(data, title) {
        if (data) {
            // @ts-ignore
            $.fn.center = function () {
                this.css("position", "absolute");
                this.css("top", ($(window).height() - this.height()) / 7 + $(window).scrollTop() + "px");
                this.css("left", ($(window).width() - this.width()) / 7 + $(window).scrollLeft() + "px");
                return this;
            }

            //@ts-ignore
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                title: title + '<a href=\"javascript:;\"  class=\"btn-i-nao right fechar_msg\"></a>',
                message: data,
                themedCSS: {
                    position: 'absolute',
                    width: '80%',
                    height: 'auto',
                    left: '10%',
                    top: '10%'
                }
            });

            $('.fechar_msg').click(() => {
                //@ts-ignore
                $.unblockUI();
            });

            // @ts-ignore
            $('.blockUI.blockMsg').center();
        }
    };

    /**
     * Método responsável por abrir o modal de aguarde ao iniciar um processo.
     */
    static startWait() {
        // @ts-ignore
        $.blockUI({message: '<h2>Aguarde...</h2>'});
    }

    /**
     * Método responsável por fechar o modal de aguarde ao concluir um processo.
     */
    static stopWait() {
        // @ts-ignore
        $.unblockUI();
    }

    /**
     * Método responsável por criar um elemento loading.
     */
    static createLoadingElement() {
        const loaddingElement = document.createElement('img');
        loaddingElement.src = '/images/spinner.gif';

        return loaddingElement;
    }

    /**
     * Método responsável por retornar um objeto Headers configurado.
     */
    static getHeadersJson() {
        const headers = new Headers();

        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Accept', 'application/json');

        return headers;
    }

    /**
     * Método responsável por retornar um objeto Headers configurado.
     */
    static getHeadersText() {
        const headers = new Headers();

        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Accept', 'application/text');

        return headers;
    }

}
